import * as React from "react"
import * as styles from './index.module.scss';

import VideoBg from '../images/video-bg.mp4';

import Layout from "../page-components/layout/layout";
// import IndexCard from "../page-components/cards/index-card";
// import KnowledgeCard from "../page-components/knowledge/knowledge-card";

import CarbonWoodBanner from "./CarbonWoodBanner.jpg";
import CarbonWoodBannerHandy from "./CarbonWoodBannerHandy.png";

import ChevronRightSVG from "../icons/fontawesome/light/chevron-right.svg";

import loadable from '@loadable/component'
import { graphql, Link } from "gatsby";
import {IndexOverview} from "../page-components/index/index-overview";
import {IndexBanner} from "../page-components/index/index-banner";
import {IndexSlider} from "../page-components/index/index-slider";
import {IndexLogos} from "../page-components/index/index-logos";
import {IndexBestsellers} from "../page-components/index/index-bestsellers";
import {IndexLatest} from "../page-components/index/index-latest";
const LazyIndexLogoballCard = loadable( () => import('../page-components/cards/index-logoball-card.js') );

const IndexPage = ( { data }) => {
  return <Layout>

    <div className={styles.videoContainer + " "}>

      <video preload="metadata" loop muted autoPlay playsInline className={styles.video} >
        <source src={VideoBg} type="video/mp4"/>
      </video>


      <div className={styles.videoGradient} />

      <div className={styles.videoContent + " pb-5"}>
        <h1>Online-Shop für Golf</h1>
        <p>Golf-Schläger, individuell gedruckte Logo-Artikel, Golf Trolleys, ...</p>
      </div>

    </div>

    <div className="bg-light-gray">
      <div className="container">
        <div className="row pt-5">

          <IndexSlider />

          <IndexBanner />

          <IndexBestsellers />
          
          <IndexLatest />

          <IndexLogos />

          <div className="py-3 py-lg-5">
            <div className="col-12">
              <h2>Von Profi Driver bis hin zu Damen Golfschuhen</h2>
              <p>
                Golfsport.at bietet Ihnen ein umfassendes Sortiment und liefert aus Österreich. Wir bieten den günstigsten Preis an.
                Bei individuell angedruckten Golfbällen unterstützen wir Sie mit unserer umfassenden Erfahrung.
                Damit Sie immer den perfekten Ball in Ihren Händen halten.
              </p>
            </div>
          </div>


        </div>
      </div>
    </div>

    <div className="container">
      <div className="row py-3 py-lg-5">
        <LazyIndexLogoballCard />
      </div>
    </div>

    <div className="container pb-5">
      <div className="row">
        <div className="col-12 mt-1">
          <h3>Unsere Kategorien</h3>
        </div>

        <IndexOverview json={data} />

        {/*{ data.allStrapiCategories.nodes.map(*/}
        {/*    strapiCat => {*/}

        {/*      return <>*/}
        {/*        <div className={"col-6 mt-3"}>*/}
        {/*          <strong className={styles.categoryTitle}>{strapiCat.title}</strong>*/}

        {/*          <ul className={"mt-2 " + styles.nav}>*/}
        {/*            { strapiCat.subCategories.map(*/}
        {/*                subCat => {*/}
        {/*                  return <li className={"d-flex align-items-center text-primary mb-1"}>*/}
        {/*                    <ChevronRightSVG className={"me-1 svgFill"} />*/}
        {/*                    <Link className={"text-primary"} to={"/kategorie/" + subCat.shopifyCollection.handle}>{subCat.shopifyCollection.title}</Link>*/}
        {/*                  </li>*/}
        {/*                }*/}
        {/*            )}*/}
        {/*          </ul>*/}
        {/*        </div>*/}
        {/*      </>*/}

        {/*    }*/}
        {/*)}*/}
      </div>
    </div>

    {/*<div class={"mt-5 bg-light-gray"}>*/}
    {/*  <div className="container py-5">*/}
    {/*    <div className="row">*/}
    {/*        <div className="col-12 mb-3">*/}
    {/*          <h2>Wissensbeiträge</h2>*/}
    {/*        </div>*/}

    {/*        <KnowledgeCard title={"Der richtige Driver"} src={Knowledge1} />*/}
    {/*        <KnowledgeCard title={"Golf für Anfänger"} src={Knowledge2} />*/}
    {/*        <KnowledgeCard title={"Perfekt abschlagen"} src={Knowledge3} />*/}

    {/*    </div>*/}
    {/*  </div>*/}
    {/*</div>*/}

  </Layout>
}

export default IndexPage;

export const query = graphql`
    query IndexCategoriesQuery {                
          strapiMenu {
              elements {
                category {
                  id
                }
                id
                label
              }
          }
            
          allStrapiCategories {
              nodes {
                subCategories {
                  id
                  shopifyCollection {
                    title
                    handle
                    imageUrl
                  }
                }
                title
                id
                tag
                strapiId
                description                 
              }
          }
      }
`

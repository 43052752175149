import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import * as styles from './index-latest.module.scss'
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

export const IndexLatest = () => {
  const data = useStaticQuery(graphql`
    query LatestProductsQuery {
      allShopifyProduct(
        sort: {fields: createdAt, order: DESC}
        limit: 20
      ) {
        nodes {
          id
          title
          handle
          createdAt
          priceRangeV2 {
            minVariantPrice {
              amount
              currencyCode
            }
          }
          featuredImage {
            gatsbyImageData(width: 300, height: 300)
          }
        }
      }
    }
  `)

  const products = data.allShopifyProduct.nodes

  // If no products are found, don't render the component
  if (!products || products.length === 0) {
    return null;
  }

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    autoplay: true,
    autoplaySpeed: 3000,
    pauseOnHover: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      }
    ]
  }

  return (
    <div className={styles.latestSection}>
      <h2 className={styles.sectionTitle}>Neuheiten</h2>
      <div className={styles.sliderContainer}>
        <Slider {...settings}>
          {products.map(product => (
            <div key={product.id} className={styles.productCard}>
              {product.featuredImage && (
                <GatsbyImage
                  image={product.featuredImage.gatsbyImageData}
                  alt={product.title}
                />
              )}
              <h3>{product.title}</h3>
              <p className={styles.price}>
                {product.priceRangeV2.minVariantPrice.amount} {product.priceRangeV2.minVariantPrice.currencyCode}
              </p>
              <Link to={`/produkt/${product.handle}`} className={styles.productLink}>
                Mehr Details
              </Link>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

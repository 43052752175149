import React from "react";
import { useEffect, useState } from "react";
import { getStrapiUrl } from "../../helper/gsConstants";
import Slider from "react-slick";

// Import slick styles
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

export const IndexSlider = () => {
    const [data, setData] = useState(null);

    useEffect(() => {
        const action = async () => {
            const res = await fetch(`${getStrapiUrl()}/slider-startseite`);
            const json = await res.json();
            setData(json.Slider);
        }

        action();
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        arrows: true,
        adaptiveHeight: true,
        pauseOnHover: true
    };

    if (!data) {
        return null;
    }

    return (
        <div className="index-slider tw-mt-10">
            <style>
                {`
                    .index-slider .slick-prev,
                    .index-slider .slick-next {
                        width: 40px;
                        height: 40px;
                        z-index: 1;
                    }
                    .index-slider .slick-prev {
                        left: 20px;
                    }
                    .index-slider .slick-next {
                        right: 20px;
                    }
                    .index-slider .slick-prev:before,
                    .index-slider .slick-next:before {
                        color: #4f8a26;
                        font-size: 40px;
                        opacity: 1;
                    }
                    .index-slider .slick-prev:hover:before,
                    .index-slider .slick-next:hover:before {
                        color: #447723;
                    }
                `}
            </style>
            <Slider {...settings}>
                {data.map((slide, index) => (
                    <div key={slide.id || index}>
                        <a href={slide.url} className="tw-block tw-relative">
                            <img 
                                src={slide.image.formats.large.url} 
                                alt={slide.image.alternativeText || ''} 
                                className="tw-w-full tw-h-auto"
                            />
                        </a>
                    </div>
                ))}
            </Slider>
        </div>
    );
}

import React, { useEffect, useState } from 'react';
import Marquee from 'react-fast-marquee';

export const IndexLogos = () => {
  const [logos, setLogos] = useState([]);

  useEffect(() => {
    const fetchLogos = async () => {
      try {
        const response = await fetch('https://strapi.golfsport.at/logo-startseite');
        const data = await response.json();
        if (data && data.Logo) {
          setLogos(data.Logo);
        }
      } catch (error) {
        console.error('Error fetching logos:', error);
      }
    };

    fetchLogos();
  }, []);

  return (
    <div className="col-12 py-4">

      <h2 className="tw-mb-3">Unsere Partner</h2>

      <Marquee
        gradient={false}
        speed={50}
        pauseOnHover={true}
      >
        {logos.map((logo, index) => (
          <div key={logo.id} className="mx-4">
            <img
              src={logo.image.formats.small.url}
              alt={logo.image.alternativeText || 'Partner logo'}
              style={{ 
                height: '130px',
                width: 'auto',
                objectFit: 'contain'
              }}
            />
          </div>
        ))}
      </Marquee>
    </div>
  );
};
